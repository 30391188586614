import styled from 'styled-components';
import { Common, device } from '@stockbit/ui';
import { logoCompanyAnimated } from '../keyframes';

const { Flex } = Common;

const CompanyRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  max-width: 1050px;

  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const CompanyLogo = styled(Flex).attrs({
  align: 'center',
  justify: 'center',
  p: '30px 15px',
})`
  filter: grayscale(100%);
  transform: scale(0.9);

  :nth-child(5),
  :nth-child(6) {
    display: none;
  }

  @media ${device.tablet} {
    :nth-child(5),
    :nth-child(6) {
      display: flex;
    }
  }

  img {
    width: 100%;
    max-height: 45px;
  }

  &.active {
    animation-name: ${logoCompanyAnimated};
    animation-duration: 0.6s;
    animation-fill-mode: forwards;

    :nth-child(1) {
      animation-delay: 0.3s;
    }

    :nth-child(1).active {
      animation-delay: 0.3s;
    }

    :nth-child(2).active {
      animation-delay: 0.5s;
    }

    :nth-child(3).active {
      animation-delay: 0.1s;
    }

    :nth-child(4).active {
      animation-delay: 0.5s;
    }

    :nth-child(5).active {
      animation-delay: 0.3s;
    }

    :nth-child(6).active {
      animation-delay: 0.2s;
    }

    :nth-child(2).active {
      animation-delay: 0.3s;
    }

    :nth-child(3).active {
      animation-delay: 0.1s;
    }

    :nth-child(4).active {
      animation-delay: 0.2s;
    }

    :nth-child(5).active {
      animation-delay: 0.5s;
    }

    :nth-child(6).active {
      animation-delay: 0.2s;
    }

    :nth-child(1).active {
      animation-delay: 0.4s;
    }
  }
`;

export { CompanyRow, CompanyLogo };
