/* eslint-disable react/no-array-index-key */
import { Common, color } from '@stockbit/ui';
import Image from 'global/Image';
import { useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Wrapper } from '../styled';
import { CompanyRow, CompanyLogo } from './styled';
import { companyLogos } from '../constants';

const { Text } = Common;

function Stocks() {
  const [viewed, setViewed] = useState(false);

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <Wrapper align="center">
        <Text
          family="bold"
          align="center"
          color={color.midgray}
          size="13px"
          mb="50px"
          style={{ textTransform: 'uppercase', letterSpacing: '.1em' }}
        >
          Invest di saham ternama. Miliki bisnisnya.
        </Text>
        {companyLogos.map((row, idx) => (
          <CompanyRow key={idx}>
            {row.map((column, idc) => (
              <CompanyLogo key={idc} className={viewed && 'active'}>
                <Image src={column} alt="company" />
              </CompanyLogo>
            ))}
          </CompanyRow>
        ))}
      </Wrapper>
    </ReactVisibilitySensor>
  );
}

export default Stocks;
